<template>
  <section
    id="dashboard-analytics"
  >
    <b-overlay
      :show="data.showLoader"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <b-row v-if="data.showSetup">
        <b-col
          lg="12"
          md="12"
        >
          <aequip-setup
            :data="data.showSetup"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="data.showIandA"
        class="match-height"
      >
        <b-col
          lg="6"
          md="12"
        >
          <analytics-insights :data="data.insights" />
        </b-col>
        <b-col
          lg="6"
          md="12"
        >
          <analytics-actions :data="data.actions" />
        </b-col>
      </b-row>
      <b-row
        v-if="data.showStats"
        class="match-height"
      >
        <b-col
          lg="2"
          md="12"
        >
          <analytics-bkpi-score :data="data.belongingness" />
        </b-col>
        <b-col
          lg="2"
          md="12"
        >
          <analytics-bkpi-score :data="data.psychologicalSafety" />
        </b-col>
        <b-col
          lg="2"
          md="12"
        >
          <analytics-bkpi-score :data="data.organisationalTrust" />
        </b-col>
        <b-col
          lg="2"
          md="12"
        >
          <analytics-bkpi-score :data="data.engagement" />
        </b-col>
        <b-col
          lg="2"
          md="12"
        >
          <analytics-bkpi-score :data="data.diversityClimate" />
        </b-col>
        <b-col
          lg="2"
          md="12"
        >
          <analytics-bkpi-score :data="data.aequipScore" />
        </b-col>
      </b-row>
      <b-row v-if="data.showSurveyDetails">
        <b-col
          lg="12"
          md="12"
        >
          <survey-details
            :details="data.surveyDetails"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import AnalyticsActions from './analytics/AnalyticsActions.vue'
import AnalyticsInsights from './analytics/AnalyticsInsights.vue'
import AnalyticsBkpiScore from './analytics/AnalyticsBkpiScore.vue'
import SurveyDetails from './survey/SurveyDetails.vue'
import AequipSetup from './actions/Setup.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsInsights,
    AnalyticsActions,
    AnalyticsBkpiScore,
    BOverlay,
    SurveyDetails,
    AequipSetup,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    /* eslint-disable global-require */
    const userdata = JSON.parse(localStorage.getItem('userData'))
    console.log(userdata)
    this.data = {
      showLoader: true,
      showStats: false,
      showIandA: false,
      showSetup: false,
      showSurveyDetails: false,
      insights: {
        stage: 0,
        lowestScores: '',
        bestScore: '',
      },
      actions: {
        stage: 0,
      },
      belongingness: {
        radial: {
          colorA: '#34c672',
          colorB: '#6fc794',
          colorBG: '#d8f4e4',
          colorText: '#5e5873',
          series: [0],
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
        },
        graph: {
          color: '#34c672',
          series: [
            {
              name: 'People with this score',
              data: [0],
            },
          ],
        },
        card: {
          class: 'card-belongingness',
        },
        title: {
          class: 'text-b-green text-align-center',
          hover: 'Being accepted and included in the team',

        },
        name: 'Belongingness',
        scoreName: 'Belongingness',

        text: {
          p1: 'As many as',
          percentage: '0%',
          p2: 'of employees report low or very low belongingness',
        },
      },
      psychologicalSafety: {
        radial: {
          colorA: '#f984d3',
          colorB: '#f6bae2',
          colorBG: '#fee6f6',
          colorText: '#5e5873',
          series: [0],
          change: {
            icon: 'None', // 'ChevronDownIcon',
          },
        },
        graph: {
          color: '#f984d3',
          series: [
            {
              name: 'People with this score',
              data: [0],
            },
          ],
        },
        card: {
          class: 'card-psychological-safety',
        },
        title: {
          class: 'text-b-pink text-align-center',
          hover: 'Having a social environment that is safe for risk-taking',
        },
        name: 'Psychological Safety',
        scoreName: 'Psychological Safety',
        text: {
          p1: 'As many as',
          percentage: '0%',
          p2: 'of employees report low or very low psychological safety',
        },
      },
      organisationalTrust: {
        radial: {
          colorA: '#1186ff',
          colorB: '#86bcf8',
          colorBG: '#ddedff',
          colorText: '#5e5873',
          series: [0],
          change: {
            icon: 'None',
          },
        },
        graph: {
          color: '#1186ff',
          series: [
            {
              name: 'People with this score',
              data: [0],
            },
          ],
        },
        card: {
          class: 'card-organisational-trust',
        },
        title: {
          class: 'text-b-blue text-align-center',
          hover: 'Knowing the organisation as a fair player/good actor',

        },
        name: 'Organisational Trust',
        scoreName: 'Organisational Trust',

        text: {
          p1: 'As many as',
          percentage: '0%',
          p2: 'of employees report low or very low trust in the organisation',
        },
      },
      engagement: {
        radial: {
          colorA: '#fc9531',
          colorB: '#f7c092',
          colorBG: '#ffead6',
          colorText: '#5e5873',
          series: [0],
          change: {
            icon: 'None',
          },
        },
        graph: {
          color: '#fc9531',
          series: [
            {
              name: 'People with this score',
              data: [0],
            },
          ],
        },
        card: {
          class: 'card-engagement',
        },
        title: {
          class: 'text-b-orange text-align-center',
          hover: 'Feeling strong motivation to do get the job done',
        },
        name: 'Engagement',
        scoreName: 'Engagement',

        text: {
          p1: 'As many as',
          percentage: '0%',
          p2: 'of employees report low or very low engagement',
        },
      },
      diversityClimate: {
        radial: {
          colorA: '#7367f0',
          colorB: '#b1acf1',
          colorBG: '#ebe9fc',
          colorText: '#5e5873',
          series: [0],
          change: {
            icon: 'None',
          },
        },
        graph: {
          color: '#7367f0',
          series: [
            {
              name: 'People with this score',
              data: [0],
            },
          ],
        },
        card: {
          class: 'card-diversity-climate',
        },
        title: {
          class: 'text-b-purple text-align-center',
          hover: 'Believing in the strength of diversity',
        },
        name: 'Diversity Climate',
        scoreName: 'Diversity Climate',
        text: {
          p1: 'As many as',
          percentage: '0%',
          p2: 'of employees report low or very low diversity-climate at the organisation',
        },
      },
      aequipScore: {
        radial: {
          colorA: '#CD9D37',
          colorB: '#DEB044',
          colorBG: '#285c54',
          colorText: '#fff',
          series: [0],
          change: {
            icon: 'None',
          },
        },
        graph: {
          color: '#DEB044',
          series: [
            {
              name: 'People with this score',
              data: [0],
            },
          ],
        },
        card: {
          class: 'card-aequip-score',
        },
        title: {
          class: 'text-b-yellow text-align-center',
          hover: 'Our overall score based on the BIG 5',
        },
        name: 'Flourishing Score',
        scoreName: 'Flourishing',
        text: {
          p1: 'As many as',
          percentage: '0%',
          p2: 'of employees scored low or very low in overall Flourishing Score',
        },
      },
      surveyDetails: {
        endDate: '',
        startDate: '',
        surveyName: '',
        percentageCompleted: '0',
        totalUsers: 1,
        completedUsers: 0,
        surveyEnded: false,
        surveyId: '',
      },
    }
    this.getData()
  },
  methods: {
    convertGraphData(graphData) {
      const newGraphData = []
      graphData.forEach((value, index) => {
        const newValue = (value === null) ? 0 : value
        newGraphData.push([index, newValue])
      })
      return newGraphData
    },
    getData() {
      axios
        .get('/home/data')
        .then(response => {
          console.log(response)
          const {
            bkpi, surveyData, surveyEnded, surveyUsersTotal, surveyCompletedTotal,
          } = response.data
          if (surveyData !== null) {
            // console.log(bkpi)
            this.data.insights.stage = 2
            this.data.actions.stage = 2
            this.data.showSetup = false
            this.data.showStats = false
            this.data.showSurveyDetails = true

            this.data.surveyDetails.endDate = new Date(surveyData.endDate * 1000).toLocaleString('default', {
              weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short',
            })
            this.data.surveyDetails.startDate = new Date(surveyData.startDate * 1000).toLocaleString('default', {
              weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short',
            })
            this.data.surveyDetails.totalUsers = surveyUsersTotal
            this.data.surveyDetails.completedUsers = surveyCompletedTotal
            const completedPercentage = Math.round((surveyCompletedTotal / surveyUsersTotal) * 100)

            this.data.surveyDetails.percentageCompleted = completedPercentage
            this.data.surveyDetails.surveyEnded = surveyEnded
            this.data.surveyDetails.surveyName = surveyData.name
            this.data.surveyDetails.surveyId = surveyData.surveyId

            if (bkpi && Object.prototype.hasOwnProperty.call(bkpi, 'belongingness')) {
              this.data.insights.stage = 3
              this.data.actions.stage = 3
              this.data.showSetup = false
              this.data.showStats = true
              this.data.showSurveyDetails = true

              this.data.belongingness.radial.series = [bkpi.belongingness * 10]
              this.data.psychologicalSafety.radial.series = [bkpi.psychologicalSafety * 10]
              this.data.organisationalTrust.radial.series = [bkpi.organisationalTrust * 10]
              this.data.engagement.radial.series = [bkpi.engagement * 10]
              this.data.diversityClimate.radial.series = [bkpi.diversityClimate * 10]
              this.data.aequipScore.radial.series = [bkpi.aequipScore * 10]

              const bkpiArray = [
                { id: 'belongingness', text: 'Belongingness', value: bkpi.belongingness },
                { id: 'psychologicalSafety', text: 'Psychological Safety', value: bkpi.psychologicalSafety },
                { id: 'organisationalTrust', text: 'Organisational Trust', value: bkpi.organisationalTrust },
                { id: 'engagement', text: 'Engagement', value: bkpi.engagement },
                { id: 'diversityClimate', text: 'Diversity Climate', value: bkpi.diversityClimate }]
              bkpiArray.sort((a, b) => ((a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))) /* eslint-disable-line no-nested-ternary */
              console.log(bkpiArray)
              this.data.insights.lowestScores = `${bkpiArray[0].text} and ${bkpiArray[1].text}`
              this.data.insights.bestScore = bkpiArray[4].text

              this.data.belongingness.graph.series[0].data = this.convertGraphData(bkpi.histogram.belongingness, 'Belongingness')
              this.data.psychologicalSafety.graph.series[0].data = this.convertGraphData(bkpi.histogram.psychologicalSafety, 'Psychological Safety')
              this.data.organisationalTrust.graph.series[0].data = this.convertGraphData(bkpi.histogram.organisationalTrust, 'Organisational Trust')
              this.data.engagement.graph.series[0].data = this.convertGraphData(bkpi.histogram.engagement, 'Engagement')
              this.data.diversityClimate.graph.series[0].data = this.convertGraphData(bkpi.histogram.diversityClimate, 'Diversity Climate')
              this.data.aequipScore.graph.series[0].data = this.convertGraphData(bkpi.histogram.aequipScore, 'Aequip')
              console.log(this.data)

              this.data.belongingness.text.percentage = `${bkpi.histogram.percentageLowBelongingness}%`
              this.data.psychologicalSafety.text.percentage = `${bkpi.histogram.percentageLowPsychologicalSafety}%`
              this.data.organisationalTrust.text.percentage = `${bkpi.histogram.percentageLowOrganisationalTrust}%`
              this.data.engagement.text.percentage = `${bkpi.histogram.percentageLowEngagement}%`
              this.data.belongingness.text.percentage = `${bkpi.histogram.percentageLowBelongingness}%`
              this.data.diversityClimate.text.percentage = `${bkpi.histogram.percentageLowDiversityClimate}%`
              this.data.aequipScore.text.percentage = `${bkpi.histogram.percentageLowAequipScore}%`
            }
          } else {
            this.data.insights.stage = 1
            this.data.actions.stage = 1
            this.data.showSetup = true
            this.data.showStats = false
            this.data.showSurveyDetails = false
          }
          this.data.showLoader = false
          this.data.showIandA = true
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
